import React from 'react';

export default () => (
  <svg
    width='61'
    height='61'
    viewBox='0 0 61 61'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M48.6807 42.6864C48.4159 42.9511 48.1016 43.1611 47.7557 43.3044C47.4098 43.4477 47.0391 43.5214 46.6646 43.5214C46.2902 43.5214 45.9195 43.4477 45.5736 43.3044C45.2277 43.1611 44.9134 42.9511 44.6486 42.6864C44.3839 42.4216 44.1739 42.1073 44.0306 41.7614C43.8873 41.4155 43.8136 41.0448 43.8136 40.6704C43.8136 40.296 43.8873 39.9252 44.0306 39.5793C44.1739 39.2334 44.3839 38.9191 44.6486 38.6544C44.8439 38.4688 45.064 38.3113 45.3027 38.1864L42.9027 35.7864C42.7775 36.0246 42.6178 36.243 42.4286 36.4344C41.894 36.9691 41.1688 37.2694 40.4126 37.2694C39.6565 37.2694 38.9313 36.9691 38.3966 36.4344C37.862 35.8997 37.5616 35.1745 37.5616 34.4184C37.5616 33.6622 37.862 32.9371 38.3966 32.4024C38.5919 32.2168 38.812 32.0593 39.0507 31.9344L28.0286 20.9004L21.0146 27.9264L43.7547 50.6664L50.4566 50.3484L50.7447 43.6524L49.1306 42.0384C49.0114 42.2742 48.86 42.4923 48.6807 42.6864Z'
      fill='#90CBEE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.549 12.446C10.496 14.4995 9.09808 17.1156 8.53186 19.9635C7.96564 22.8115 8.2566 25.7633 9.36795 28.4459C10.4793 31.1285 12.3611 33.4213 14.7755 35.0344C17.1899 36.6475 20.0283 37.5085 22.932 37.5085C25.8357 37.5085 28.6742 36.6475 31.0885 35.0344C33.5029 33.4213 35.3847 31.1285 36.4961 28.4459C37.6074 25.7633 37.8984 22.8115 37.3322 19.9635C36.7659 17.1156 35.368 14.4995 33.315 12.446C31.9516 11.0823 30.3329 10.0005 28.5514 9.26247C26.7699 8.52441 24.8604 8.14453 22.932 8.14453C21.0036 8.14453 19.0942 8.52441 17.3126 9.26247C15.5311 10.0005 13.9124 11.0823 12.549 12.446ZM21.027 20.9C20.515 21.4116 19.8629 21.7599 19.153 21.9009C18.4431 22.0419 17.7073 21.9693 17.0387 21.6922C16.3701 21.4151 15.7987 20.946 15.3966 20.3441C14.9946 19.7423 14.78 19.0348 14.78 18.311C14.78 17.5873 14.9946 16.8798 15.3966 16.278C15.7987 15.6761 16.3701 15.207 17.0387 14.9299C17.7073 14.6528 18.4431 14.5802 19.153 14.7212C19.8629 14.8622 20.515 15.2105 21.027 15.722C21.7125 16.4093 22.0975 17.3404 22.0975 18.311C22.0975 19.2817 21.7125 20.2128 21.027 20.9Z'
      fill='#1D417D'
    />
  </svg>
);
