import React from 'react';

export default () => (
  <svg
    width='61'
    height='60'
    viewBox='0 0 61 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M33.8287 28.6232L29.3315 24.126L8.13106 45.3265L12.6283 49.8237L33.8287 28.6232Z'
      fill='#1D417D'
    />
    <path
      d='M41.0983 21.3527L36.6011 16.8555L31.5269 21.9297L36.0241 26.4269L41.0983 21.3527Z'
      fill='#90CBEE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23.2097 16.0263L23.8877 16.1343C23.9402 16.1343 23.9905 16.1551 24.0277 16.1923C24.0648 16.2294 24.0857 16.2798 24.0857 16.3323C24.0857 16.3848 24.0648 16.4351 24.0277 16.4723C23.9905 16.5094 23.9402 16.5303 23.8877 16.5303L23.2097 16.6383C22.0499 16.8307 20.9795 17.3815 20.1488 18.2133C19.318 19.0452 18.7686 20.1163 18.5777 21.2763L18.4697 21.9483C18.4697 22.0008 18.4488 22.0511 18.4117 22.0883C18.3745 22.1254 18.3242 22.1463 18.2717 22.1463C18.2191 22.1463 18.1688 22.1254 18.1317 22.0883C18.0945 22.0511 18.0737 22.0008 18.0737 21.9483L17.9597 21.2763C17.7687 20.1163 17.2193 19.0452 16.3886 18.2133C15.5578 17.3815 14.4874 16.8307 13.3277 16.6383L12.6497 16.5303C12.5971 16.5303 12.5468 16.5094 12.5097 16.4723C12.4725 16.4351 12.4517 16.3848 12.4517 16.3323C12.4517 16.2798 12.4725 16.2294 12.5097 16.1923C12.5468 16.1551 12.5971 16.1343 12.6497 16.1343L13.3277 16.0263C14.4857 15.8345 15.5549 15.2855 16.3855 14.456C17.216 13.6265 17.7664 12.5581 17.9597 11.4003L18.0737 10.7283C18.0737 10.6758 18.0945 10.6254 18.1317 10.5883C18.1688 10.5511 18.2191 10.5303 18.2717 10.5303C18.3242 10.5303 18.3745 10.5511 18.4117 10.5883C18.4488 10.6254 18.4697 10.6758 18.4697 10.7283L18.5777 11.4003C18.7709 12.5581 19.3213 13.6265 20.1519 14.456C20.9824 15.2855 22.0516 15.8345 23.2097 16.0263Z'
      fill='#90CBEE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M50.1975 38.8441L51.1635 39.0001C51.243 39.0001 51.3193 39.0317 51.3756 39.088C51.4319 39.1443 51.4635 39.2206 51.4635 39.3001C51.4635 39.3797 51.4319 39.456 51.3756 39.5123C51.3193 39.5685 51.243 39.6001 51.1635 39.6001L50.1795 39.7621C48.4898 40.0439 46.9304 40.8465 45.7191 42.0578C44.5078 43.269 43.7052 44.8285 43.4235 46.5181L43.2615 47.5021C43.2615 47.5817 43.2299 47.658 43.1736 47.7143C43.1173 47.7705 43.041 47.8021 42.9615 47.8021C42.8819 47.8021 42.8056 47.7705 42.7493 47.7143C42.6931 47.658 42.6615 47.5817 42.6615 47.5021L42.4995 46.5181C42.2215 44.826 41.4213 43.2632 40.2109 42.0485C39.0005 40.8339 37.4406 40.0281 35.7495 39.7441L34.7595 39.6001C34.6799 39.6001 34.6036 39.5685 34.5473 39.5123C34.4911 39.456 34.4595 39.3797 34.4595 39.3001C34.4595 39.2206 34.4911 39.1443 34.5473 39.088C34.6036 39.0317 34.6799 39.0001 34.7595 39.0001L35.7495 38.8381C37.443 38.5579 39.0063 37.7546 40.2201 36.5408C41.4339 35.327 42.2373 33.7637 42.5175 32.0701L42.6795 31.0861C42.6795 31.0066 42.7111 30.9303 42.7673 30.874C42.8236 30.8177 42.8999 30.7861 42.9795 30.7861C43.059 30.7861 43.1353 30.8177 43.1916 30.874C43.2479 30.9303 43.2795 31.0066 43.2795 31.0861L43.4415 32.0701C43.7198 33.7631 44.5209 35.3265 45.7324 36.5413C46.944 37.7561 48.5053 38.5613 50.1975 38.8441Z'
      fill='#90CBEE'
    />
  </svg>
);
