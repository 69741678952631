import React from 'react';

const Home = () => (
  <svg
    width='60'
    height='61'
    viewBox='0 0 60 61'
    fill='none'
    style={{ marginLeft: '-5px' }}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.6601 13.6113L16.6201 24.7713V52.6713H28.9801V40.2513C28.9801 37.6713 31.0801 35.5713 33.6601 35.5713C36.2401 35.5713 38.3401 37.6713 38.3401 40.2513V52.6713H50.7001V24.7713L33.6601 13.6113Z'
      fill='#1D417D'
    />
    <path
      d='M50.7001 19.1912C53.7819 19.1912 56.2801 16.693 56.2801 13.6112C56.2801 10.5295 53.7819 8.03125 50.7001 8.03125C47.6184 8.03125 45.1201 10.5295 45.1201 13.6112C45.1201 16.693 47.6184 19.1912 50.7001 19.1912Z'
      fill='#90CBEE'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.7602 39.5913C20.7602 35.0313 17.0402 31.3113 12.4802 31.3113C7.92019 31.3113 4.2002 35.0313 4.2002 39.5913C4.2002 43.6113 7.08019 46.9713 10.9202 47.6913V52.4913H14.0402V47.6913C17.8202 46.9713 20.7602 43.6113 20.7602 39.5913Z'
      fill='#90CBEE'
    />
  </svg>
);

export default Home;
