import React from 'react';

const DirectLending = () => (
  <svg
    width='60'
    height='61'
    viewBox='0 0 60 61'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M33.3899 37.0821C41.8067 37.0821 48.6299 30.2589 48.6299 21.8421C48.6299 13.4252 41.8067 6.60205 33.3899 6.60205C24.9731 6.60205 18.1499 13.4252 18.1499 21.8421C18.1499 30.2589 24.9731 37.0821 33.3899 37.0821Z'
      fill='#90CBEE'
    />
    <path
      d='M55.4101 32.882C54.7501 32.462 53.8501 32.462 53.1901 32.882L36.1501 42.122C34.9501 42.782 33.5101 42.902 32.1901 42.362L25.5301 39.902C24.9901 39.722 24.8701 38.942 25.4101 38.642C25.5901 38.522 25.8301 38.522 26.0101 38.582L31.0501 40.502C32.1901 40.922 33.5701 40.622 34.2901 39.602C35.3101 38.222 34.7101 36.302 33.1501 35.642L24.6901 32.102C24.0301 31.802 23.3101 31.862 22.6501 32.102L14.3101 35.702C14.3101 35.702 14.3101 35.702 14.3101 35.762V41.942V50.162V50.222L32.1901 54.422C33.2701 54.782 34.4101 54.542 35.2501 53.822L55.6501 36.302C56.6701 35.282 56.6101 33.662 55.4101 32.882Z'
      fill='#1D417D'
    />
    <path
      d='M14.2499 34.3223H3.62988V52.0223H14.2499V34.3223Z'
      fill='#90CBEE'
    />
    <path
      d='M34.4099 13.9219V15.8419C35.5499 15.9619 36.5099 16.2619 37.2899 16.7419L36.0899 19.0219C35.4299 18.4819 34.7699 18.2419 34.1099 18.2419C33.6899 18.2419 33.3899 18.3619 33.1499 18.5419C32.9099 18.7219 32.7899 18.9619 32.7899 19.1419C32.7899 19.3819 32.9099 19.5619 33.0899 19.7419C33.2699 19.9219 33.5699 20.1019 33.8699 20.2219L34.8299 20.5219C35.8499 20.8819 36.6299 21.3019 37.1099 21.8419C37.5899 22.3819 37.8299 23.0419 37.8299 23.8819C37.8299 24.3619 37.7699 24.8419 37.6499 25.2619C37.5299 25.6819 37.3499 25.9819 37.0499 26.2819C36.8099 26.5819 36.4499 26.8819 36.0299 27.1219C35.6099 27.3619 35.0699 27.5419 34.4699 27.7819V29.7619H33.0299V27.8419C32.7299 27.8419 32.3699 27.7819 32.0099 27.7219C31.6499 27.6619 31.2899 27.5419 30.9299 27.4219C30.5699 27.3019 30.2099 27.1219 29.9099 26.9419C29.6099 26.7619 29.3099 26.5819 29.1299 26.4019L30.3899 24.0019C31.4099 24.9019 32.3699 25.3219 33.2699 25.3219C33.6899 25.3219 33.9899 25.2019 34.2899 25.0219C34.5899 24.7819 34.7099 24.5419 34.7099 24.1819C34.7099 23.7019 34.3499 23.3419 33.6899 23.1619L32.7899 22.8619C32.2499 22.6819 31.7699 22.5019 31.4099 22.3219C31.0499 22.1419 30.6899 21.9019 30.4499 21.6019C30.2099 21.3619 30.0299 21.0619 29.9099 20.7019C29.7899 20.3419 29.7299 19.9819 29.7299 19.5619C29.7299 19.0819 29.7899 18.6619 29.9099 18.2419C30.0299 17.8219 30.2699 17.4619 30.5099 17.1019C30.8099 16.7419 31.1099 16.5019 31.5299 16.2619C31.9499 16.0219 32.4299 15.9019 32.9699 15.8419V13.9219H34.4099Z'
      fill='white'
    />
  </svg>
);

export default DirectLending;
