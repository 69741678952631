import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import ButtonLink from '../Shared/ButtonLink';
import CTASimpleStyles from './CTASimple.styled';
import theme from '../../styles/theme';
import createClasslist from '../../util/createClasslist';

const CTASimple = ({
  appearance = 'light',
  background = theme.colors.vmfBlue,
  condensed = false,
  title = '',
  link = null,
  secondaryLink = null,
  children,
  isAnimated = true,
  icon = false,
  className = '',
}) => {
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const classes = createClasslist(
    'block-cta_simple',
    icon && 'block-cta_cimple--icon',
    isAnimated && 'isAnimated',
    inView && 'active',
    className
  );

  return (
    <CTASimpleStyles
      appearance={appearance}
      background={background}
      condensed={condensed}
      ref={ref}
      className={classes}
    >
      <div className='inner'>
        <header>
          {icon}
          {title && <h2>{title}</h2>}
        </header>
        <section className='content'>{children}</section>
        <div className='links'>
          {link && link.to && link.title && (
            <ButtonLink appearance={appearance} {...link}>
              {link.title}
            </ButtonLink>
          )}
          {secondaryLink && secondaryLink.to && secondaryLink.title && (
            <ButtonLink
              appearance={appearance}
              outline={true}
              {...secondaryLink}
            >
              {secondaryLink.title}
            </ButtonLink>
          )}
        </div>
      </div>
    </CTASimpleStyles>
  );
};

CTASimple.propTypes = {
  appearance: PropTypes.oneOf(['light', 'dark']),
  background: PropTypes.string,

  condensed: PropTypes.bool,
  title: PropTypes.string,
  link: PropTypes.shape({
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  isAnimated: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.bool,
  ]),
};

export default CTASimple;
