import styled from 'styled-components';

export default styled.section`
  padding: ${props => props.mobileTopPadding} ${props => props.theme.padding.inner} 4.875rem;

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    padding: ${props => props.tabletTopPadding} ${props => props.theme.padding.inner} 5.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoints.desktop}) {
    padding: ${props => props.desktopTopPadding} ${props => props.theme.padding.innerLarge} 10.1875rem;
  }

  .inner {
    max-width: ${props => props.theme.maxWidths.containerSmall};
    margin: auto;
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-direction: row;
      max-width: ${props => props.theme.maxWidths.container};
      justify-content: space-between;
    }
  }

  .cta {
    margin-bottom: 2.8125rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      min-width: 260px;
      margin-right: 1rem;
      padding-top: 60px;
    }

    h2 {
      margin: 0;
      padding-top: 1.875rem;
    }

    @media (prefers-reduced-motion: no-preference) {
      .isAnimated& {
        opacity: 0;
        transform: translateX(-10px);
      }

      .active& {
        animation: 0.4s ease-in-out slide-in-right forwards;
      }
    }
  }

  .payment_options {
    border-left: solid 2px ${props => props.theme.colors.babyBlue};
    padding-left: 0.8125rem;
    margin: 1rem 0 0;

    h3 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
    }

    a {
      color: ${props => props.theme.colors.vmfBlue};
      font-size: ${props => props.theme.type.button.small};
      line-height: ${props => props.theme.lineHeights.button.small};
    }
  }

  .button-link {
    margin-top: 2rem;
    margin-bottom: 10px;
  }

  .loans {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;    

    @media (prefers-reduced-motion: no-preference) {
      .isAnimated& {
        opacity: 0;
        transform: translateX(-10px);
        transition: all 0.4s ease-in-out;
        transition-delay: 0.2s;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: 2rem;
      max-width: 47.7rem;
      justify-content: space-around;      
    }

    .active& {
      opacity: 1;
      transform: none;
    }

    .loan {
      padding: 0.5rem 0;
      box-sizing: border-box;      
      text-align: center;

      a {
        box-sizing: border-box;
        display: inline-block;
        padding: 0.45rem 0.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        background: ${props => props.theme.colors.white};
        border-radius: ${props => props.theme.borderRadiuses.card};
        box-shadow: ${props => props.theme.shadows.low};
        transition: all 0.2s ease-in-out;
        height: 100%;

        &:hover,
        &:active,
        &:focus {
          box-shadow: ${props => props.theme.shadows.high};
        }
      }

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        width: 50%;
        padding: 14px 1.4375rem;

        &:nth-child(odd) {
          padding-left: 0;
        }

        &:nth-child(even) {
          padding-right: 0;
        }
      }

      h4 {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 0.75rem;

        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          margin-left: 2rem;
        }
        @media (max-width: ${props => props.theme.breakpoints.tablet}) {
          width: 100%;
          justify-content: space-between;
        }

        svg {
          height: 1em;
          margin-top: 0.1rem;
          color: ${props => props.theme.colors.vmfBlue};
        }
      }
    }
  }

  ${props => props.theme.animations.slideInRight};
`;
