import styled from 'styled-components';
import { maxWidths } from '../../styles/variables';

const CTASimpleStyles = styled.section`
  padding: ${props => (props.condensed ? '6.81rem 1rem 7rem' : '8.06rem 1rem')};
  background: ${props => props.background || rgba(0, 0, 0, 0.5)};
  color: ${props =>
    props.appearance === 'dark'
      ? props.theme.colors.grey1
      : props.theme.colors.white};

  .inner {
    margin: 0 auto;
    max-width: ${props => props.theme.maxWidths.containerSmall};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media (prefers-reduced-motion: no-preference) {
      .isAnimated& {
        opacity: 0;
        transform: translateY(10px);
        transition: all 0.4s ease-in-out;
      }
    }

    .active& {
      opacity: 1;
      transform: none;
    }

    @media (min-width: ${props => props.theme.breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.ctaSimple};
    }

    

    header {
      display: flex;
      flex-direction: row;

      svg {
        margin-right: 1.125rem;
        padding-right: 1.125rem;
        border-right: solid 1px ${props => props.theme.colors.grey4};
      }

      h2 {
        .block-cta_simple--icon& {
          text-align: left;
        }
      }
    }

    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: inherit;
      width: 100%;
    }

    h2 {
      margin: 0;
    }

    p {
      margin-bottom: 0;
    }

    .links {
      width: 100%;
      margin-top: 1.56rem;
      display: flex;
      flex-direction: row;
      justify-content: center;

      a {
        &:nth-child(2) {
          margin-left: 1rem;
        }
      }
    }
  }

  .content {
    width: 100%;

    .type-intro {
      color: ${props => props.theme.colors.darkBlue};
    }

    > * {
      width: 100%;
    }
  }
`;

export default CTASimpleStyles;