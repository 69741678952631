import React from 'react';

export default () => (
  <svg
    width='60'
    height='60'
    viewBox='0 0 60 60'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M48.6597 27.8281H11.3457V49.5901H48.6597V27.8281Z'
      fill='#1D417D'
    />
    <path
      d='M48.6597 17.5137H11.3457V24.2637H48.6597V17.5137Z'
      fill='#90CBEE'
    />
    <path d='M18.6841 11.832H14.8081V19.176H18.6841V11.832Z' fill='#1D417D' />
    <path d='M44.5918 11.832H40.7158V19.176H44.5918V11.832Z' fill='#1D417D' />
  </svg>
);
